import React from "react";

import DefaultLayout from "../../../layouts/default";

const Mitgliedschaften = () => (
  <DefaultLayout title="Mitgliedschaften">
    <div className="orange portrait">
      <div className="navContentSpacerHelper"></div>
      <header className="navWrapper">
        <nav className="mainNav">
          <div className="mainNavBackgroundHelper"></div>
          <div className="logo">
            <a href="/">
              <img
                src="/assets/images/logos/wigasoft-logo.svg"
                alt="WigaSoft Logo"
                width="175"
              />
            </a>
          </div>
          <div className="mainNav-opener"></div>
          <ul className="mainLinks">
            <li>
              <a href="/news/">News</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/heime/">Heime</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/spitaeler/">Spitäler</a>
            </li>
            <li>
              <a href="/dokumentationsloesungen/rehabilitation/">
                Rehabilitation
              </a>
            </li>
            <li className="is-active">
              <a href="/portrait/">Über uns</a>
            </li>
            <li>
              <a href="/support/">Support</a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/wigasoft-ag">
                <div className="linkedInIcon" />
              </a>
            </li>
          </ul>
        </nav>
        <nav className="subNav2">
          <div className="subNav2Product">
            <div className="productText">
              <span className="line">Mitgliedschaften&nbsp;</span>
              <span className="line">der WigaSoft AG</span>
            </div>
          </div>
          <div className="subNav2-opener"></div>
          <ul className="subNav2Links">
            <li>
              <a href="/portrait/">Übersicht</a>
            </li>
            <li>
              <a href="/portrait/lehrlinge/">Lernende</a>
            </li>
            <li>
              <a href="/portrait/partner/">Partner</a>
            </li>
            <li>
              <a className="is-active" href="/portrait/mitgliedschaften/">
                Mitgliedschaften
              </a>
            </li>
            <li>
              <a href="/portrait/kontakt/">Kontakt</a>
            </li>
          </ul>
        </nav>
      </header>

      <div className="menuBackground">
        <div className="wrapper">
          <section className="has-padding-top">
            <h1>Mitgliedschaften</h1>
            <p>
              Die WigaSoft AG setzt auf starke Partnerschaften mit bewährten und
              etablierten Anbietern von Dienstleistungen und Werkzeugen für das
              Gesundheitswesen. Auf dieser Basis können komplexe, durchgängige
              und für jeden Anwender spezifisch passende IT Lösungen erfolgreich
              gestaltet werden. Für die Verbindung mit administrativen und
              betriebswirtschaftlichen Spitallösungen arbeitet die WigaSoft AG
              mit allen Spitalsoftware-Anbietern zusammen. Das Ziel dieser
              Projektkooperationen ist ein spitalweiter und transparenter
              Datenfluss für sämtliche Führungs-, Stamm- und
              betriebswirtschaftlichen Daten.
            </p>
          </section>

          <section className="has-padding-top has-padding-large-bottom">
            <div className="partnerFlex">
              <div className="partnerVertFlexOneCol">
                <div className="partnerItem">
                  <img
                    className="has-height-105 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/ALIS.svg"
                  />
                  <h4>ALIS Connect</h4>
                  <p className="fliesstext">
                    Seit über 15 Jahren die Standardschnittstelle auf der Basis
                    XML für die Leistungsübermittlung zwischen
                    Informatiksystemen in Spitälern (reine Erfassungssysteme,
                    RIS, KIS etc. zu Abrechnungssystemen) und eine solche für
                    die Übermittlung von Codierungsdaten nach den
                    unterschiedlichen Anforderungen der Leistungserbringer,
                    Versicherer sowie der öffentlichen Hand.
                    <br />
                    <br />
                    <span className="footnote">
                      Selbstverständlich ist die WigaSoft AG zertifizierter
                      ALIS-Anbieter.
                    </span>
                  </p>
                  <a href="https://www.alis-connect.ch/" target="_blank">
                    <button className="large notThatLarge">ALIS Connect</button>
                  </a>
                </div>

                <div className="partnerItem">
                  <img
                    className="has-height-89 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/IHE_Suisse.png"
                  />
                  <h4>IHE Suisse</h4>
                  <p className="fliesstext">
                    Die IHE (Integrating the Healthcare Enterprise) ist eine
                    internationale Initiative zur Verbesserung des
                    elektronischen Datenaustausches zwischen IT Systemen im
                    Gesundheitswesen.
                  </p>
                  <a href="https://www.ihe-suisse.ch/" target="_blank">
                    <button className="large notThatLarge">IHE Suisse</button>
                  </a>
                </div>

                <div className="partnerItem">
                  <img
                    className="has-height-89 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/IHK_Logo_kurz.jpg"
                  />
                  <h4>IHK St.&nbsp;Gallen-Appenzell</h4>
                  <p className="fliesstext">
                    Die IHK ist ein branchenübergreifender Verband, bei welchem
                    rund 1600 mehrheitlich klein- und mittelständische
                    Unternehmen Mitglied sind.
                  </p>
                  <p className="fliesstext">
                    Die Industrie- und Handelskammer setzt sich zum Ziel, in den
                    Kantonen St.Gallen und Appenzell beider Rhoden die
                    Interessen der Industrie, des Handels und der
                    Dienstleistungsunternehmen zu wahren und zu fördern.
                  </p>
                  <a href="https://www.ihk.ch/" target="_blank">
                    <button className="large notThatLarge">IHK Suisse</button>
                  </a>
                </div>
                {/* <!---thirdcolitems----> */}

                <div className="partnerItem thirdColumnItem">
                  <img
                    className="has-height-100 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/hl7-logo.svg"
                  />
                  <h4>HL7 Schweiz</h4>
                  <p className="fliesstext">
                    HL7 steht für «Health Level Seven» und ist ein
                    internationaler Standard für den elektronischen
                    Datenaustausch im Gesundheitswesen.
                  </p>
                  <a href="https://www.hl7.ch/" target="_blank">
                    <button className="large notThatLarge">HL7 Schweiz</button>
                  </a>
                </div>

                <div className="partnerItem thirdColumnItem">
                  <img
                    className="smallStaticPadding-bottom"
                    style={{ height: 168 }}
                    src="/assets/images/partner-logos/logo-ICT_BB_Ostschweiz.jpg"
                  />
                  <h4>ICT Berufsbildung Ostschweiz</h4>
                  <p className="fliesstext">
                    Der ICT Berufsbildung Ostschweiz ist die Dachorganisation
                    für die Durchführung der überbetrieblichen Kurse, die ein
                    wichtiger Bestandteil der Informatik-Ausbildung sind sowie
                    die Anlaufstelle bei Fragen und Problemen.
                  </p>
                  <a href="https://ict-berufsbildung-ost.ch/" target="_blank">
                    <button
                      className="large notThatLarge"
                      style={{ width: "auto", paddingLeft: 9, paddingRight: 9 }}
                    >
                      ICT Berufsbildung Ostschweiz
                    </button>
                  </a>
                </div>

                <div className="partnerItem thirdColumnItem">
                  <img
                    className="has-height-64 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/logo_wisg.png"
                  />
                  <h4>Wirtschaftsregion St.&nbsp;Gallen</h4>
                  <p className="fliesstext">
                    Die WISG setzt sich für eine erfolgreiche Entwicklung des
                    Wirtschaftszentrums St.&nbsp;Gallen ein.
                  </p>
                  <a href="http://www.wisg.ch/" target="_blank">
                    <button className="large notThatLarge">WISG</button>
                  </a>
                </div>


                <div className="partnerItem thirdColumnItem">
                  <img
                    className="has-height-64 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/logo-svdg.jpg"
                  />
                  <h4>SVDG - Schweizer Verband Digitale Gesundheit</h4>
                  <p className="fliesstext">
                    Der Schweizer Verband Digitale Gesundheit SVDG ist ein Verband für technische Anbieter im digitalen Gesundheitsmarkt.
                  </p>
                  <a href="http://www.svdg.ch/" target="_blank">
                    <button className="large notThatLarge">SVDG</button>
                  </a>
                </div>


                <div className="partnerItem thirdColumnItem">
                  <img
                    className="has-height-64 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/senesuisse.jpg"
                  />
                  <h4>senesuisse - Verband wirtschaftlich unabhängiger Alters- und Pflegeeinrichtungen Schweiz</h4>
                  <p className="fliesstext">
                    Der Verband senesuisse vertritt die Interessen und Anliegen wirtschaftlich unabhängiger Leistungserbringer im Bereich der Langzeitpflege. Er engagiert sich gegen ständig steigende Vorschriften, Bürokratie und Administrativaufwand im Bereich des Gesundheitswesens. Qualität entsteht nicht durch Vorschriften, sondern durch Selbstverantwortung, Kundenorientierung und unternehmerische Freiheiten.
                  </p>
                  <a href="https://www.senesuisse.ch/de/" target="_blank">
                    <button className="large notThatLarge">senesuisse</button>
                  </a>
                </div>

                {/* <!---thirdcolitemsover--> */}
              </div>

              <div className="partnerVertFlexOneCol">
                <div className="partnerItem">
                  <img
                    className="has-height-114 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/H+.png"
                  />
                  <h4>H+ die Spitäler der Schweiz</h4>
                  <p className="fliesstext">
                    H+ vertritt als Spitzenverband die Interessen der rund 400
                    Schweizer Spitäler, Kliniken, Rehabilitations- und
                    Langzeitinstitutionen als Aktivmitglieder und rund 250
                    Institutionen, Firmen und Personen als
                    Partnerschaftsmitglieder.
                  </p>
                  <a href="http://www.hplus.ch" target="_blank">
                    <button className="large notThatLarge">H+</button>
                  </a>
                </div>

                <div className="partnerItem">
                  <img
                    className="has-height-89 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/it-sg.png"
                  />
                  <h4>IT St.&nbsp;Gallen rockt</h4>
                  <p className="fliesstext">
                    «IT St.&nbsp;Gallen rockt!» ist ICT Cluster der
                    St.GallenBodenseeArea. Die Initiative ist ein
                    Zusammenschluss der ICT-Unternehmen, Bildungsinstitute,
                    Netzwerkpartner und der öffentlichen Hand der
                    St.GallenBodenseeArea.
                  </p>
                  <a href="https://www.itrockt.ch/" target="_blank">
                    <button className="large notThatLarge">IT SG</button>
                  </a>
                </div>

                <div className="partnerItem">
                  <img
                    className="has-height-105 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/SwissICT.png"
                  />
                  <h4>SwissICT</h4>
                  <p className="fliesstext">
                    SwissICT ist einer der führenden Verbände der Informations-
                    und Kommunikationstechnologie der Schweiz.
                  </p>
                  <a href="http://www.swissict.ch/" target="_blank">
                    <button className="large notThatLarge">Swiss ICT</button>
                  </a>
                </div>
                {/* <!---thirdColItem--> */}
                <div className="partnerItem thirdColumnItem">
                  <img
                    className="has-height-64 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/logo_wisg.png"
                  />
                  <h4>Wirtschaftsregion St.&nbsp;Gallen</h4>
                  <p className="fliesstext">
                    Die WISG setzt sich für eine erfolgreiche Entwicklung des
                    Wirtschaftszentrums St.&nbsp;Gallen ein.
                  </p>
                  <a href="http://www.wisg.ch/" target="_blank">
                    <button className="large notThatLarge">WISG</button>
                  </a>
                </div>
                <div className="partnerItem thirdColumnItem">
                  <img
                    className="smallStaticPadding-bottom"
                    style={{ height: 168 }}
                    src="/assets/images/partner-logos/logo-ICT_BB_Ostschweiz.jpg"
                  />
                  <h4>ICT Berufsbildung Ostschweiz</h4>
                  <p className="fliesstext">
                    Der ICT Berufsbildung Ostschweiz ist die Dachorganisation
                    für die Durchführung der überbetrieblichen Kurse, die ein
                    wichtiger Bestandteil der Informatik-Ausbildung sind sowie
                    die Anlaufstelle bei Fragen und Problemen.
                  </p>
                  <a href="https://ict-berufsbildung-ost.ch/" target="_blank">
                    <button
                      className="large notThatLarge"
                      style={{ width: "auto", paddingLeft: 9, paddingRight: 9 }}
                    >
                      ICT Berufsbildung Ostschweiz
                    </button>
                  </a>
                </div>


                <div className="partnerItem">
                  <img
                    className="has-height-64 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/SG_Health_Logo_Member_RGB_pos.png"
                  />
                  <h4>St. Gallen Health</h4>
                  <p className="fliesstext">
                    St. Gallen Health ist ein Netzwerk, welches lokale Kompetenzen vernetzt und Expertise verbindet. Durch gemeinsames Zusammenwirken und dank kurzer Wege werden Synergien und einen Gesundheitsstandort mit nationaler und internationaler Ausstrahlungskraft geschaffen.
                  </p>
                  <a href="https://sg-health.ch/de/" target="_blank">
                    <button className="large notThatLarge">St. Gallen Health</button>
                  </a>
                </div>
              </div>
              <div className="partnerVertFlexOneCol thirdColumn">
                <div className="partnerItem">
                  <img
                    className="has-height-100 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/hl7-logo.svg"
                  />
                  <h4>HL7 Schweiz</h4>
                  <p className="fliesstext">
                    HL7 steht für «Health Level Seven» und ist ein
                    internationaler Standard für den elektronischen
                    Datenaustausch im Gesundheitswesen.
                  </p>
                  <a href="https://www.hl7.ch/" target="_blank">
                    <button className="large notThatLarge">HL7 Schweiz</button>
                  </a>
                </div>

                <div className="partnerItem">
                  <img
                    className="smallStaticPadding-bottom"
                    style={{ height: 168 }}
                    src="/assets/images/partner-logos/logo-ICT_BB_Ostschweiz.jpg"
                  />
                  <h4>ICT Berufsbildung Ostschweiz</h4>
                  <p className="fliesstext">
                    Der ICT Berufsbildung Ostschweiz ist die Dachorganisation
                    für die Durchführung der überbetrieblichen Kurse, die ein
                    wichtiger Bestandteil der Informatik-Ausbildung sind sowie
                    die Anlaufstelle bei Fragen und Problemen.
                  </p>
                  <a href="https://ict-berufsbildung-ost.ch/" target="_blank">
                    <button
                      className="large notThatLarge"
                      style={{ width: "auto", paddingLeft: 9, paddingRight: 9 }}
                    >
                      ICT Berufsbildung Ostschweiz
                    </button>
                  </a>
                </div>

                <div className="partnerItem">
                  <img
                    className="has-height-64 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/logo_wisg.png"
                  />
                  <h4>Wirtschaftsregion St.&nbsp;Gallen</h4>
                  <p className="fliesstext">
                    Die WISG setzt sich für eine erfolgreiche Entwicklung des
                    Wirtschaftszentrums St.&nbsp;Gallen ein.
                  </p>
                  <a href="http://www.wisg.ch/" target="_blank">
                    <button className="large notThatLarge">WISG</button>
                  </a>
                </div>


                <div className="partnerItem">
                  <img
                    className="has-height-64 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/logo-svdg.jpg"
                  />
                  <h4>SVDG - Schweizer Verband Digitale Gesundheit</h4>
                  <p className="fliesstext">
                    Der Schweizer Verband Digitale Gesundheit SVDG ist ein Verband für technische Anbieter im digitalen Gesundheitsmarkt.
                  </p>
                  <a href="http://www.svdg.ch/" target="_blank">
                    <button className="large notThatLarge">SVDG</button>
                  </a>
                </div>


                <div className="partnerItem">
                  <img
                    className="has-height-64 smallStaticPadding-bottom"
                    src="/assets/images/partner-logos/senesuisse.jpg"
                  />
                  <h4>senesuisse - Verband wirtschaftlich unabhängiger Alters- und Pflegeeinrichtungen Schweiz</h4>
                  <p className="fliesstext">
                    Der Verband senesuisse vertritt die Interessen und Anliegen wirtschaftlich unabhängiger Leistungserbringer im Bereich der Langzeitpflege. Er engagiert sich gegen ständig steigende Vorschriften, Bürokratie und Administrativaufwand im Bereich des Gesundheitswesens. Qualität entsteht nicht durch Vorschriften, sondern durch Selbstverantwortung, Kundenorientierung und unternehmerische Freiheiten.
                  </p>
                  <a href="https://www.senesuisse.ch/de/" target="_blank">
                    <button className="large notThatLarge">senesuisse</button>
                  </a>
                </div>


              </div>
            </div>
          </section>
        </div>

            <section className="newsletterAnmeldung">
      <div className="wrapper nlPadding-top">
        <a className="btn grey" href="/newsletter">Newsletteranmeldung</a><br /><br /><br />
      </div>
    </section>

        <footer className="mainFooter">
          <div className="wrapper">
            <div className="breadcrumbs has-padding-small-top smallStaticPadding-bottom">
              <ul>
                <li>
                  <a href="/">Startseite</a>
                </li>
                <li>
                  <a href="/portrait/">Über uns</a>
                </li>
                <li>
                  <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                </li>
              </ul>
            </div>
            <div className="footerNotAnotherFlexboxButAGrid linklist">
              <div className="flex-item1">
                <ul>
                  <li>
                    <a href="/dokumentationsloesungen/heime/">
                      <strong>Heime</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/spitaeler/">
                      <strong>Spitäler</strong>
                    </a>
                  </li>
                  <li>
                    <a href="/dokumentationsloesungen/rehabilitation/">
                      <strong>Rehabilitation</strong>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item2">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      BESA
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-l/index_rai">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-L
                      RAI-NH
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-b/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-B
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-h/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-H
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-doc-r/">
                      <span className="is-visible-desktop">WiCare|Doc</span>
                      <span className="is-visible-tablet-until-PC">WCD</span>-R
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item3">
                <ul>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-tacs/">
                      WiCare|now tacs<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-lep/">
                      WiCare|now LEP<sup>&#174;</sup>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/dokumentationsloesungen/wicare-le/">
                      WiCare|now LE
                    </a>
                  </li>
                </ul>
              </div>
              <div className="flex-item4">
                <ul>
                  <li>
                    <a href="/portrait/">
                      <strong>Über uns</strong>
                    </a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/lehrlinge/">Lernende</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/partner/">Partner</a>
                  </li>
                  <li className="partiallyHidden">
                    <a href="/portrait/mitgliedschaften/">Mitgliedschaften</a>
                  </li>
                  <li>
                    <a href="/portrait/kontakt/">Kontakt und Anfahrt</a>
                  </li>
                </ul>
              </div>
              <div className="flex-item5">
                <ul>
                  <li>
                    <a href="/support/">
                      <strong>Support</strong>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row copyright">
            <div className="wrapper">
              <div className="column">
                © 2025 WigaSoft. Alle Rechte vorbehalten.&nbsp;
              </div>
              <div className="column has-padding-left">
                <ul>
                  <li>
                    <a href="/datenschutz/">Datenschutzerklärung</a>
                  </li>
              <li>
                <a href="/impressum/">Impressum</a>
              </li>
                </ul>
              </div>
              <div className="column align-right">
                <a href="/portrait/kontakt/" className="anfahrt">
                  <img
                    id="location-pointer"
                    src="/assets/images/icons/icon-anfahrt.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </DefaultLayout>
);

export default Mitgliedschaften;
